import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/NavbarTwo"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'


const PrivacyPolicy = () => {
    return (
        <Layout>
            <Navbar />
           
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="privacy-policy-content">
                              
                                <h3>Terms of Use</h3>
                                
                                <blockquote className="blockquote">
                                Minsony Ltd (“we”, or “Minsony”) a company registered in England with company number 15126215.
Minsony Ltd owns and operates the website www.minsony.com and its related sites, applications
(including mobile sites and applications), services and technology that are made available by Minsony
together with any content, tools, forums, information sharing, and transactions available herein
(collectively, the “Service”). <br /> <br />
These terms of use govern your access to and use of the Service (“Terms of Use”).<br /> <br />
The Terms of Use shall be deemed to include all other operating rules, policies and procedures that are
referred to herein or that may otherwise be published by Minsony from time to time, including without
limitation, the following:<br /> <br />
For purposes of these Terms of Use, the “User” (“you”) shall mean either you as an individual or the
entity on whose behalf you are accepting these Terms of Use and who is afforded all rights and bound
by all terms hereunder. User represents that he/she/it is authorized to accept these Terms of Use.
                                </blockquote>
                            

1) Supply of the Service<br /> <br />
1.1 Minsony shall make the Service available to the Users on the basis of these Terms of Use.<br /> <br />
1.2 By using the Service, Users agree to be bound by these Terms of Use. Users should not
create an account or use the Service if they do not agree to the Terms of Use.<br /> <br />
1.3 The User shall be deemed to agree to the Terms of Use by ticking the box confirming that
they have read and accept these Terms of Use.<br /> <br />
1.4 We reserve the right to update, change or replace any part of these Terms of Use by posting
updates and/or changes to our website. It is your responsibility to check this page
periodically for changes. Your continued use of our service or access to the website
following the posting of any changes constitutes acceptance of those changes.<br /> <br />
1.5 Due to the changing nature of laws, regulations and the content of this website, Users
should ensure they regularly review all disclaimer and data protection statements posted on
this site.<br /> <br />
2) Obligations of all Users<br /> <br />
2.1 The User must not use or associate themselves with any other company and/or the
company registered address and must operate as a separate entity entirely. The user must
not register their company to the address and have no letters/parcels sent to the premises.
Failing to do so will result in a permanent ban of the use of the service and website.<br /> <br />
2.2 The user is not premised to loiter around the main entrance or the communal area.<br /> <br />

2.3 The User agrees not to take any action using any aspect of the Service, in any manner that:<br /> <br />
a) Impersonates any person or entity, including any employee or representative of
Minsony Ltd.<br /> <br />
b) Infringes any patent, trademark, trade secret, copyright, right of publicity, privacy right
or other right of any party.<br /> <br />
c) Is unlawful, deceptive, misleading, fraudulent, threatening, abusive, harassing, invasive
of another’s privacy or which otherwise violates this Terms of Use.<br /> <br />
d) Reveals any personal information about another individual, including another person’s
name, address, phone number, electronic email address, credit card information or any
other information that could be used to track, contact, or impersonate that person.<br /> <br />
2.4 Users must not use the service or website for any purpose that is unlawful or prohibited by
these Terms of Use.<br /> <br />
2.5 No children under the age of 16 are permitted to enter the building, failing to comply will
result in your booking session to be to be cancelled with immediate effect and without any
refund issued.<br /> <br />
2.6 Users shall comply with Applicable Laws and ensure that Health and Safety standards are
maintained at all times.<br /> <br />
2.7 You must provide accurate, complete, and current registration information at all times.
Failure to do so constitutes a breach of these Terms of Use, which may result in immediate
termination of service. You are entirely responsible for maintaining the confidentiality of
your password and account. Furthermore, you are entirely responsible for any and all
activities that occur under your account. You agree to notify Minsony immediately of any
unauthorized use of your account or any other breach of security. Minsony will not be liable
for any loss that you may incur as a result of someone else using your password or account,
either with or without your knowledge. However, you could be held liable for losses
incurred by Minsony or another party due to someone else using your account or password.
You must not use anyone else’s account at any time without the permission of the account
holder.<br /> <br />
2.8 Users are responsible to undertake and maintain all relevant insurance, licensing, consent,
permissions, correct qualifications and any other necessary means to operate their business
and provide treatments to their clients and must keep up to date with the laws and
regulations. If a User has any doubt about what insurance is required, they should consult
their own insurers or insurance brokers.<br /> <br />
3) Booking<br /> <br />
3.1 Bookings may only be made by a person aged 18 or above.
<br /> <br />
3.2 Bookings are for a short term stay for business and does not give rise to an assured
shorthold tenancy or lease.<br /> <br />
3.3 All bookings that are made online via our website and are confirmed via email. Only
bookings that appear on the system are confirmed as valid.<br /> <br />
3.4 By making a booking you agree that you will check-in at the time as stated on your booking,
and check-out at the time as stated on your booking and no later. Failure to check-out at the
time stated on your booking will incur a late check-out charge of £50 that will be payable at
time of check-out.<br /> <br />
3.5 User must provide identification when checking in. You can use any of the following as
identification; a passport, driving license or ID card.<br /> <br />
3.6 User agrees that it will not:<br /> <br />
a. other than as permitted by these terms of use, permit any third party to access and/or
use the room;<br /> <br />
b. rent, lease, loan, or sell access to the Services to any third party;<br /> <br />
c. interfere with, disrupt, alter, translate, or modify the room<br /> <br />
d. use the room for any purpose other than for its own internal business purposes;<br /> <br />
e. no access and no use of the building/room outside the agreed hours<br /> <br />
f. no access and no use of the building/room outside the trading hours of 10am to 8pm<br /> <br />
3.7 Minsony reserves the right to terminate the User’s access to the room or the account of any
nominated user in the event Minsony believes the room is being used in breach of the
provisions of this clause and Terms of Use.<br /> <br />
4) Fees and Payment<br /> <br />
4.1 The Fees are payable in advance as detailed on our website and will be presented to you
before you confirm your booking and make payment.<br /> <br />
4.2 Prices for our rooms are subject to change without notice. We reserve the right at any time
to modify the service without notice at any time.<br /> <br />
4.3 We shall not be liable to the User or to any third-party for any modification, suspension or
price change of Minsony rooms.<br /> <br />
4.4 Failure to check-out at the time stated on your booking will result in a £50 charge payable
at time of check-out as stated in clause 3.4 of this Terms of Use.<br /> <br />
4.5 Long-term booking agreements are on a month-month basis with payments due on or
before the 1st of each calendar month.<br /> <br />
4.6 For long-term booking arrangements, deposits equal to one month's rent are required, and
payment must be made in advance.<br /> <br />
4.7 Payments may be made by cash, debit card, credit card or bank transfer and must be in
cleared funds by the deadlines for payment as set out in clause 4.5.<br /> <br />
4.8 Key holders must report loss of key immediately to management. A £100 charge payable for
replacement of key or fob.<br /> <br />
5) Cancellations, Rescheduling and Refunds<br /> <br />
5.1 User must provide 72 hour’s notice in order to cancel/reschedule an online booking of any
Minsony rooms. Bookings will remain in place and you will be charged the full rental rate if
your cancellation/reschedule request is within 72 hours of the booking start time. Under no
circumstances should a refund be issued inside the 72-hour window.<br /> <br />
5.2 Refunds will not be issued if User fails to comply with clause 2.7 of the Terms of Use.<br /> <br />
5.3 Refunds may be issued if the room is no longer available or contains health and safety
hazards that would be reasonably expected to adversely affect the User.<br /> <br />
5.4 If the User does not make any payment due to us by the due date for payment as set out in
clause 4.5 we may cancel your booking and terminate any agreement.<br /> <br />
5.5 The User is required to provide 30 days notice of termination of any long-term agreement
which must be submitted in writing.<br /> <br />
6) Property<br /> <br />
6.1 Minsony management will be responsible for the maintenance and upkeep of the
communal area, and for the provision of tissues, lavatory paper, and cleaning materials.<br /> <br />
6.2 Users must ensure rooms are left clean and tidy at the end of each session, and in the state
in which you would expect to find them; this should include wiping down surfaces with
disinfectant wipes and cleaning products provided in the basin unit. Failure to do so will
result in refusal of future bookings or termination of the contract.<br /> <br />
6.3 The User must conduct themselves and ensure that their clients under their booking
conduct themselves, appropriately at all times and comply with any requests and notices
regarding conduct, health and safety and respecting the premises, our neighbours, our
employees, and the other Users and clients.<br /> <br />
6.4 If needles, syringes or sharps are found in the bin provided or sharps bin is left behind, a
£500 fine will apply. All belongings must be taken after use; the room will be checked after
every user. Users are not permitted to give room keys to a third party or client.<br /> <br />
6.5 Users are not permitted to interfere with, disrupt, alter, translate, or modify the room and
any breakages and indelible marks should be reported by the User immediately.<br /> <br />
6.6 The User will remain responsible for any damage to our property or premises you or your
clients cause and must pay damages to us in respect of any such damage or loss. In the
event of such damage, we will provide you with a breakdown of the damages due. You
must pay any payments due under this clause immediately.<br /> <br />
6.7 Smoking is not permitted inside or on the premises under any circumstances.<br /> <br />
6.8 Pets inside or on the premises are not permitted under any circumstances.<br /> <br />
6.9 Our premises offer Wi-Fi subject to availability, strictly to the User. The use of Wi-Fi is
subject to the Terms of Use. In particular, the User must not use the Wi-Fi to download or
upload any unlawful, harmful or obscene materials or to place a reasonable burden on our
network.<br /> <br />
6.10 No use of anything flammable or anything producing smoke, such as candles or a diffuser, is allowed inside the building/room. Random checks of the rooms will be conducted to ensure compliance.<br /> <br />
7) Limitation of Remedies and Liability<br /> <br />
7.1 Nothing in this Terms of Use shall operate to exclude or limit Minsony's liability for:<br /> <br />
a) death or personal injury caused by its negligence; or<br /> <br />
b) any breach of the terms implied by section 12 of the Sale of Goods Act 1979 or section 2
of the Supply of Goods and Services Act 1982; or<br /> <br />
c) fraud; or<br /> <br />
d) any other liability which cannot be excluded or limited under applicable law.<br /> <br />
7.2 Minsony shall not be liable to the User for any damage to software, damage to or loss of
data, loss of profit, anticipated profits, revenues, anticipated savings, goodwill or business
opportunity, or for any indirect or consequential loss or damage.<br /> <br />
7.3 Minsony is not responsible for any loss of business, personal belongings, or any other sort of
thing.<br /> <br />
7.4 Minsony cannot be held responsible for any loss or damage to the User’s property or that
of their clients.<br /> <br />
8) Minors<br /> <br />
The use of our Service and website by a minor, aged 16 or under, is strictly prohibited as per
clause 2.4 of these Terms of Use.<br /> <br />
9) Accessibility<br /> <br />
If you or any of your Clients has any individual needs, such as reduced mobility, please
advise us before making your booking, and we will be able to check if we have rooms
available which meet your individual needs.<br /> <br />
10) Privacy Policy<br /> <br />
10.1 Minsony Privacy Policy sets out the terms on which we process any personal data we
collect from you, or you provide to us. By using our Service and website, you consent to
such processing in accordance with our Privacy Policy, and you warrant and represent:<br /> <br />
a) all personal data you provide to us is complete and accurate in all material aspects; and<br /> <br />
b) where you are providing us with the personal data of third parties, you have obtained
clear, unambiguous consent from the relevant third parties to provide us with such
personal data.<br /> <br />
11) Data Protection<br /> <br />
11.1 Where Minsony processes any User data, Minsony will comply with all requirements and
obligations under the Data Protection Laws.<br /> <br />
11.2 Minsony shall only process the User data it reasonably requires in order to provide the
Service. All User data processed by Minsony shall be in accordance with the Privacy Policy.<br /> <br />
12) Law and the Courts<br /> <br />
These Terms of Use are governed by and shall be construed in accordance with English law,
and the Courts of England and Wales shall have non-exclusive jurisdiction over any claim
arising from, or related to the use of the Service. We retain the right to bring proceedings
against you for breach of these Terms of Use in your country of residence or any other
relevant country.<br /> <br />
13) Contacting Us<br /> <br />
If you have any questions or concerns about these Terms of Use, please email us at
info@minsony.com<br /> <br />
                                
                            </div>
                        </div>
                        </div>
                        </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default PrivacyPolicy;